import React, { useEffect, useRef, useState } from 'react'

const ScrollButton = () => {
	const buttonRef = useRef(null)
	const [showButton, setShowButton] = useState(false)
	const [hideButton, setHideButton] = useState(false)
	const scrollThreshold = 240
	const bottomThreshold = 40

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY
			const distanceFromBottom = document.body.scrollHeight - (scrollPosition + window.innerHeight)

			// Check if scroll position is above or below the threshold
			setShowButton(scrollPosition >= scrollThreshold)

			// Check if we are near the bottom of the page
			setHideButton(distanceFromBottom <= bottomThreshold)
		}

		// Add scroll event listener
		window.addEventListener('scroll', handleScroll)

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, []) // Empty dependency array to ensure this runs only once on mount

	return (
		<div className="fixed bottom-6 left-1/2 z-50 -translate-x-1/2">
			<button
				ref={buttonRef}
				role="button"
				aria-label="Get in touch"
				className={`cta block rounded-full bg-aztec-950 px-6 py-2 text-sm uppercase tracking-widest text-aztec-50 ${showButton ? 'show-cta fade-up' : ''} ${hideButton ? 'hide-cta' : ''}`}
			>
				<a href="mailto:parties@albionparties.com">Get in touch</a>
			</button>
		</div>
	)
}

export default ScrollButton
